import './foundation'

$(function() {

  // Mobile Navigation
  console.clear();

    const app = (() => {
    	let body;
    	let menu;
    	let menuItems;

    	const init = () => {
    		body = document.querySelector('body');
    		menu = document.querySelector('.menu-button');
    		menuItems = document.querySelectorAll('.nav__list-item');

    		applyListeners();
    	}

    	const applyListeners = () => {
    		menu.addEventListener('click', () => toggleClass(body, 'nav-active'));
    	}

    	const toggleClass = (element, stringClass) => {
        const appContent = document.querySelector('.app-content');

    		if(element.classList.contains(stringClass)) {
          element.classList.remove(stringClass);
          const scrollY = appContent.style.top;
          appContent.style.position = '';
          appContent.style.top = '';
          window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    		else {
          element.classList.add(stringClass);
          const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
          appContent.style.position = 'fixed';
          appContent.style.top = `-${scrollY}`;
        }
    	}

      window.addEventListener('scroll', () => {
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
      });

    	init();
    })();

    // Smooth scrolling anchors
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
          e.preventDefault();

          document.querySelector(this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
          });
      });
    });

})
